import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyAbxRWPffabkM71Cmqv3StHs-SQGmBGLyc",
  authDomain: "kniched-230202.firebaseapp.com",
  projectId: "kniched-230202",
  storageBucket: "kniched-230202.appspot.com",
  messagingSenderId: "651308308488",
  appId: "1:651308308488:web:fd54b1b9db1ceee83319bd"
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const AUTH = getAuth(app)
export const DB = getFirestore(app)